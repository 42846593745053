<template>
  <b-container class="full-width no-padding-l no-padding-r">
    <b-row>
      <b-col md="6">
        <b-card>
          <b-form
            class="mt-2"
            sm="6"
          >
            <b-row v-if="formData">
              <b-col cols="12">
                <b-form-group
                  label="Show recent counter"
                  label-for="button-text"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-container class="full-width no-padding-l no-padding-r">
                    <b-row>
                      <b-col
                        cols="2"
                        style="margin-top:0.5rem"
                      >
                        <b-form-checkbox
                          id="show_recent_counter"
                          v-model="formData.show_recent_counter"
                          checked="true"
                          name="show_recent_counter"
                          switch
                          inline
                        />
                      </b-col>
                    </b-row>
                  </b-container>
                </b-form-group>
              </b-col>
              <b-col
                v-if="formData.show_recent_counter"
                cols="12"
              >
                <b-form-group
                  label="From the last"
                  label-for="recent"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <div class="form-horizontal-elem">
                    <b-form-input
                      id="recent"
                      v-model="formData.counter_from_recent_days"
                      placeholder="999"
                      name="recent"
                      style="width:70px"
                      type="number"
                      maxlength="3"
                      :formatter="formatRecentDays"
                    />
                    <label for="recent">days</label>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="formData.show_recent_counter"
                cols="12"
              >
                <b-form-group
                  label="Starting number"
                  label-for="recent"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <div class="form-horizontal-elem">
                    <b-form-input
                      id="starting_number"
                      v-model="formData.starting_number"
                      placeholder="98"
                      name="starting_number"
                      style="width:70px"
                      type="number"
                      :formatter="formatStartingNumber"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Dark Theme"
                  label-for="theme"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-form-checkbox
                    id="theme"
                    v-model="formData.theme"
                    checked="true"
                    name="theme"
                    value="dark"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Button text"
                  label-for="button-text"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="Edit2Icon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="button-text"
                      v-model="formData.button_text"
                      placeholder="Button text"
                      name="button_text"
                    />

                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Button color"
                  label-for="button-color"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="button-color"
                      v-model="formattedHexColorValue"
                      placeholder="Button color"
                      name="button_color"
                    />
                    <b-input-group-append is-text>
                      <verte
                        v-model="formattedHexColorValue"
                        picker="wheel"
                        model="hex"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Button placement"
                  label-for="button-placement"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="formData.h_placement === 'left' ? 'primary' : 'outline-primary'"
                      @click="formData.h_placement = 'left'"
                    >
                      <feather-icon
                        icon="CornerDownLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Left</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="formData.h_placement === 'right' ? 'primary' : 'outline-primary'"
                      @click="formData.h_placement = 'right'"
                    >
                      <feather-icon
                        icon="CornerDownRightIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Right</span>
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Show countdown"
                  label-for="show_countdown"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-form-checkbox
                    id="show_countdown"
                    v-model="formData.show_countdown"
                    checked="true"
                    name="show_countdown"
                    switch
                    inline
                  />
                  <div
                    v-if="formData.show_countdown"
                    class="form-label-group"
                    style="margin-top:2rem"
                  >
                    <b-form-textarea
                      id="next_service_text"
                      v-model="formData.next_service_text"
                      rows="3"
                      placeholder="Add text, before next service countdown"
                    />
                    <label for="next_service_text">Next service text</label>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Show explainer text"
                  label-for="show_explainer_text"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                >
                  <b-form-checkbox
                    id="show_explainer_text"
                    v-model="formData.show_explainer_text"
                    checked="true"
                    name="show_explainer_text"
                    switch
                    inline
                  />
                  <div
                    v-if="formData.show_explainer_text"
                    class="form-label-group"
                    style="margin-top:2rem"
                  >
                    <b-form-textarea
                      id="explainer_text"
                      v-model="formData.explainer_text"
                      rows="3"
                      placeholder="Add explainer text here"
                    />
                    <label for="explainer_text">Add explainer text here</label>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mt-2"
              >
                <b-form-group
                  class="align-items-center"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Phone number is required"
                  label-for="check-button"
                >
                  <b-form-checkbox
                    v-model="formData.is_phone_required"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                  />
                </b-form-group>

              </b-col>
            </b-row>
            <b-card-footer class="no-padding">
              <b-col cols="12">
                <b-container class="full-width no-padding">
                  <b-row>
                    <b-col
                      cols="6"
                      class="no-padding-l"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        type="reset"
                        class="mt-2"
                        @click.prevent="showConfirmResetForm"
                      >
                        Reset
                      </b-button>
                    </b-col>
                    <b-col
                      cols="6"
                      class="no-padding-r"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2"
                        :disabled="isSubmitting"
                        style="float:right"
                        @click="submitForm"
                      >
                        Save changes
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          class="browser-mockup with-url"
          no-body
        >
          <b-card-header>
            &nbsp;
          </b-card-header>
          <div
            style="width: 365px; min-height: 60px; font-family: Helvetica, Arial, serif"
            :class="['cta-elem',formData.h_placement,{ 'cta-widget-dark': formData.theme === 'dark' }]"
          >
            <div
              v-if="formData.show_recent_counter"
              class="cta_recent"
            >
              <h5>999 {{ formData.recent_counter_text }}</h5>
            </div>
            <div
              v-if="formData.show_explainer_text"
              class="cta-explainer-text"
            >
              <span
                v-if="explainerTextWithBrs !== 'null'"
                v-html="explainerTextWithBrs"
              />
            </div>
            <div
              v-if="formData.show_countdown"
              class="cta-countdown"
            >
              <span>{{ formData.next_service_text }}</span>
              <Countdown :deadline="countDownEnd" />
            </div>
            <button
              :style="{backgroundColor : formattedHexColorValue,color:textColor}"
            >
              {{ formData.button_text }}
            </button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BContainer,
  BInputGroupAppend,
  BButtonGroup,
  BCardHeader,
  BCardFooter,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { cloneDeep } from 'lodash'
import store from '@/store'
import AccountService from '@/services/AccountService'
import Verte from 'verte'
import 'verte/dist/verte.css'
import Countdown from 'vuejs-countdown'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { escapeHtmlAndConvertNlToBr, handleValidationError } from '@/utils/helpers'

dayjs.extend(duration)

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    Verte,
    BInputGroupAppend,
    BButtonGroup,
    BCardHeader,
    Countdown,
    ToastificationContent,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSubmitting: false,
      formData: null,
      countDownEnd: dayjs().add(dayjs.duration({ days: 2, hours: 7, minutes: 23 }))
        .format('YYYY-MM-DD H:m'),
      textColor: '#999999',
    }
  },
  computed: {
    formattedHexColorValue: {
      get() {
        return this.addHashToHex(this.formData.button_color)
      },
      set(val) {
        this.formData.button_color = this.removeHashFromHex(val)
        if (this.convertButtonColorWhenLowContrast(val, '#000000') > 1 / 6.5) {
          this.textColor = '#FFFFFF'
        } else {
          this.textColor = '#333'
        }
      },
    },
    // Convert new lines to <br> and escape rest of the html.
    // The result can be put directly into v-html without XSS risk because it's been escaped.
    explainerTextWithBrs() {
      return escapeHtmlAndConvertNlToBr(`${this.formData.explainer_text}`)
    },
  },
  created() {
    this.resetForm()
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true
      const data = {
        widgets: {
          cta: {
            button_text: this.formData.button_text,
            button_color: this.formData.button_color,
            h_placement: this.formData.h_placement,
            show_countdown: this.formData.show_countdown,
            next_service_text: this.formData.next_service_text,
            show_explainer_text: this.formData.show_explainer_text,
            explainer_text: this.formData.explainer_text,
            theme: this.formData.theme === 'dark' ? 'dark' : 'light',
            show_recent_counter: this.formData.show_recent_counter,
            recent_counter_text: this.formData.recent_counter_text,
            is_phone_required: this.formData.is_phone_required,
            starting_number: this.formData.starting_number,
            counter_from_recent_days: this.formData.counter_from_recent_days,
          },
        },
      }
      try {
        await AccountService.updateAccountSettings(data).then(response => {
          store.dispatch('auth/setAuthUser', response.data.data).then(this.resetForm)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Updated successfully',
              variant: 'success',
            },
          })
        }).catch(error => {
          handleValidationError(error, this)
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },
    formatRecentDays(e) {
      return String(e).substring(0, 3)
    },
    formatStartingNumber(e) {
      return String(parseFloat(e) < 0 ? 0 : e).substr(0, 4)
    },
    addHashToHex(hex) {
      return (!hex.includes('#') ? `#${hex}` : hex).trim()
    },
    removeHashFromHex(hex) {
      return (hex.includes('#') ? hex.replace('#', '') : hex).trim()
    },
    convertButtonColorWhenLowContrast(color1, color2) {
      function hexToRgb(hex) {
        if (!/^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(color1)) {
          return { r: 0, g: 0, b: 0 }
        }

        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        const converted = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(converted)
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        } : null
      }
      function luminance(r, g, b) {
        const a = [r, g, b].map(v => {
          v /= 255
          return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4)
        })
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
      }
      const color1rgb = hexToRgb(color1)
      const color2rgb = hexToRgb(color2)

      const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b)
      const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b)

      const ratio = color1luminance > color2luminance
        ? ((color2luminance + 0.05) / (color1luminance + 0.05))
        : ((color1luminance + 0.05) / (color2luminance + 0.05))

      return ratio
    },
    resetForm() {
      const ctaSettings = store.getters['auth/account'].settings.widgets.cta
      this.formData = cloneDeep(ctaSettings)
      this.formData.recent_counter_text = 'People has recently planned a visit'
    },
    showConfirmResetForm() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want reset the form', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.resetForm()
          }
        })
    },
  },

}
</script>
<style scoped="scss">
.container.full-width{
  max-width:100%;
}
.container-no-padding{
  padding-left:0;
  padding-right:0;
}
.full-width{
  width:100%;
  full-width:100%;
}
.no-padding-l{
  padding-left:0;
}
.no-padding-r{
  padding-right:0;
}
.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: '';
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0em;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}

.card.browser-mockup.with-url .card-header:after {
  display: block;
  position: absolute;
  content: 'Plan your visit';
  top: 0.8em;
  width: calc(100% - 8em);
  height: 2.1em;
  font-size: 12px;
  padding: 4px 4px 4px 12px;
  box-sizing: border-box;
  left: 6.4em !important;
  color: #9c9c9c;
  background:#FFF;
  border-radius:100px;
}

.browser-mockup > * {
  display: block;
}
.card.browser-mockup {
  flex: 1;
  min-height: 440px;
  position:relative;
  border: 1px solid #e2e2e2;
}
.card.browser-mockup .card-header{
  background-color:#F0F0F0;
  border-bottom: 1px solid #dedddd;
}
.card.browser-mockup .card-header:before{
  display: block;
  position: absolute;
  content: '';
  top: 1.3em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}
.cta-elem{
  width: 35%;
  min-height: 60px;
  background: #F0F0F0;
  box-shadow: 9px 11px 22px rgb(6 5 5 / 6%);
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  bottom: 15px;
  position: absolute;
  min-width: 365px;
  padding: 10px 20px 10px 20px;
}

.cta-elem.left{
  left:15px;
}
.cta-elem.right{
  right:15px;
}
.cta-elem button{
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  font-weight: 500;
}
.cta-button-text-dark{
  color: white;
}
.cta-explainer-text{
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 10px;
}
.cta_recent{
  text-align:center;
  margin-bottom:0.75rem;
  border-bottom: 1px solid #DADADA;
}
.cta-countdown{
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top:10px;
  padding-bottom:10px;
}
.form-horizontal-elem{
  display:flex;
  flex-flow:row;
  align-items: center;
}
.form-horizontal-elem  label{
  margin-left:0.75rem;
}
.cta-widget-dark.cta-elem{
  background: #283046;
  box-shadow: 0 4px 24px 0 rgb(34 41 47);
  border: 1px solid #171f3087;
}
.cta-widget-dark.cta-elem,
.cta-widget-dark.cta-elem *{
  color: #b4b7bd;
}
.cta-widget-dark.cta-elem button{
  border: 1px solid rgb(40 49 69);
  background: #7366f0;
  color: #FFF;
}
.cta-widget-dark.cta-elem .cta_recent,
.cta-widget-dark.cta-elem .cta-explainer-text{
  border-bottom: 1px solid #1f283a;
}
.input-group{
  max-width:100%;
}
.no-padding{
  padding:0;
}
#button-color{
  height:initial;
}
.dark-layout .cta-elem:not(.cta-widget-dark) h1,
.dark-layout .cta-elem:not(.cta-widget-dark) h2,
.dark-layout .cta-elem:not(.cta-widget-dark) h3,
.dark-layout .cta-elem:not(.cta-widget-dark) h4,
.dark-layout .cta-elem:not(.cta-widget-dark) h5,
.dark-layout .cta-elem:not(.cta-widget-dark) h6{
  color:var(--purple);
}
.dark-layout .card-header{
  background-color:var(--dark) !important;
  border-bottom: 1px solid #484848 !important;
}
.dark-layout .card.browser-mockup{
  border: 1px solid #484848;
}
.ftl-label{
  white-space: nowrap;
  margin-right: 0.85rem;
  color: #5e5873;
  font-size: 0.857rem;
  min-width: 110px;
}
@media screen and (max-width: 768px){
  .cta-elem{
    min-width: initial;
    width: auto !important;
  }
}
</style>
